import styled from 'styled-components';
import { Parallax } from 'react-parallax';
import { LoadImage, Subtitle, Title } from 'components';
import { StyledText } from '../styles';
import { px2rem } from 'src/util';

const blockHeight = 1085;

const blockHeightMobile = 579;

export const Wrapper = styled.section`
  position: relative;

  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(blockHeight / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    /* margin-bottom: 429px; */
    overflow: hidden;
    /* padding-bottom: ${(blockHeightMobile / 375) * 100}%; */
  }
`;

export const ParallaxWrapper = styled.div`
  @media screen and (min-width: 998px) {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledParallax = styled(Parallax)`
  height: 100%;
  width: 100%;
`;

export const BackgroundImage = styled(LoadImage)`
  position: absolute;
  top: 0;

  @media screen and (min-width: 998px) {
    left: 0;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 997px) {
    height: 579px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Overlay = styled.div`
  align-items: center;
  backdrop-filter: blur(${px2rem(32)});
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${Subtitle} {
    margin-bottom: ${px2rem(40)};
  }

  ${Title} {
    margin-bottom: ${px2rem(24)};
    max-width: ${px2rem(730)};
  }

  ${StyledText} {
    max-width: ${px2rem(750)};
    text-align: center;
  }

  @media screen and (min-width: 998px) {
    bottom: 0;
    /* height: ${(517 / blockHeight) * 100}%; */
    padding: ${(120 / 1920) * 100}% ${px2rem(40)};
    left: 0;
    position: absolute;
  }

  @media screen and (max-width: 997px) {
    margin-top: 350px;
    padding: 120px 20px;
    top: 350px;
  }
`;
