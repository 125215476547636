import styled from 'styled-components';
import { LoadImage, Title } from 'components';
import { StyledText } from '../styles';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media screen and (min-width: 998px) {
    padding: ${px2rem(192)} ${px2rem(40)} 0;
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
    padding: 120px 20px 0;
  }
`;

export const TextWrapper = styled.div`
  ${Title} {
    margin: ${px2rem(24)} 0;
  }

  @media screen and (min-width: 998px) {
    margin-right: ${px2rem(60)};

    ${Title} {
      max-width: ${px2rem(520)};
    }

    ${StyledText} {
      max-width: ${px2rem(500)};
    }
  }

  @media screen and (max-width: 997px) {
  }
`;

export const SideImageWrapper = styled.div`
  @media screen and (min-width: 998px) {
    align-self: flex-end;
    display: flex;
    height: ${px2rem(697)};
    opacity: 0;
    transform: translateX(50%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    width: ${px2rem(624)};

    &.isVisible {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 997px) {
    margin-top: 100px;
    height: 347px;
  }
`;

export const SideImage = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    align-self: flex-end;
    margin-top: auto;
    width: 100%;
  }
`;
