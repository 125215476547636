import React from 'react';
import { Contact, SEO } from 'components';
import {
  SobreTop,
  Integration,
  Purpose,
  Experience,
  Values,
  Driving
} from 'components/Sobre';

export default function Sobre() {
  return (
    <>
      <SEO title="Nexodata" />
      <SobreTop />
      <Integration />
      <Purpose />
      <Experience />
      <Values />
      <Driving />
      <Contact type="sobre" />
    </>
  );
}
