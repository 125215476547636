import styled from 'styled-components';
import { Title, Text } from 'components';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  background-color: #f3f1e8;
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 998px) {
    padding: ${px2rem(220)} ${px2rem(40)};

    ${Title} {
      max-width: ${px2rem(230)};
    }
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
    padding: 120px 20px;

    ${Title} {
      margin-bottom: 56px;
    }
  }
`;

export const List = styled.ul`
  @media screen and (min-width: 998px) {
    display: grid;
    gap: ${px2rem(90)} ${px2rem(120)};
    grid-template-columns: repeat(2, 1fr);
    margin-left: ${px2rem(140)};
  }

  @media screen and (max-width: 997px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const ListItem = styled.li`
  ${Text} {
    letter-spacing: -0.045em;
  }

  @media screen and (min-width: 998px) {
    max-width: ${px2rem(350)};
  }

  @media screen and (max-width: 997px) {
    max-width: ${px2rem(260)};
    text-align: center;

    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
`;

export const ItemHeader = styled.div`
  align-items: center;
  display: flex;

  @media screen and (min-width: 998px) {
    margin-bottom: ${px2rem(25)};
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

export const ItemIcon = styled.img`
  filter: drop-shadow(
    ${px2rem(3)} ${px2rem(6)} ${px2rem(11)} rgba(0, 0, 0, 0.11)
  );
  height: ${px2rem(78)};
  width: ${px2rem(78)};

  @media screen and (min-width: 998px) {
    margin-right: ${px2rem(27)};
  }

  @media screen and (max-width: 997px) {
    margin-bottom: 24px;
  }
`;

export const ItemName = styled.h3`
  font: 700 ${px2rem(24)} / 120% 'Univia Pro', 'Arial', 'sans-serif';
`;
