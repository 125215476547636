import styled from 'styled-components';
import { Subtitle, Title } from 'components';
import { StyledText, GreenText } from '../styles';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  align-items: center;
  background-color: #f1f2f5;
  display: flex;
  flex-direction: column;
  text-align: center;

  ${StyledText}, ${Title} {
    text-align: center;
  }

  ${Title} {
    margin: ${px2rem(24)} 0;
    max-width: ${px2rem(640)};

    &:not(:first-of-type) {
      margin-top: ${px2rem(80)};
    }
  }

  ${GreenText} {
    display: block;
    line-height: 133%;
  }

  @media screen and (min-width: 998px) {
    padding: ${(581 / 1920) * 100}% ${px2rem(40)} ${(270 / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    padding: ${(177 / 375) * 100}% 20px 97px;

    ${Subtitle} {
      max-width: 210px;
    }
  }
`;
