import styled from 'styled-components';
import { Title } from 'components';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 998px) {
    padding: ${px2rem(250)} ${px2rem(40)} ${px2rem(300)};
  }

  @media screen and (max-width: 997px) {
    flex-direction: column;
    padding: 160px 20px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: ${px2rem(150)};
  line-height: 115%;
  max-width: ${px2rem(720)};
`;

export const Logo = styled.img`
  @media screen and (min-width: 998px) {
    margin-left: ${px2rem(180)};
    width: ${px2rem(264)};
  }

  @media screen and (max-width: 997px) {
    margin-top: 72px;
    width: 206px;
  }
`;
