import React from 'react';
import classNames from 'classnames';
import { Subtitle, CustomVisibilitySensor } from 'components';
import { Wrapper, TextWrapper, SideImageWrapper, SideImage } from './styles';
import { SmallTitle, StyledText, GreenText } from '../styles';

export default function Experience() {
  return (
    <Wrapper>
      <TextWrapper>
        <Subtitle>Hoje proporcionamos uma</Subtitle>
        <SmallTitle>
          Melhor experiência para pacientes, médicos, hospitais, farmácias e
          empresas de tecnologia em saúde
        </SmallTitle>
        <StyledText>
          por meio da prescrição eletrônica de medicamentos. Amanhã,
          continuaremos trabalhando duro para dar a nossa melhor contribuição
          para o sistema de saúde:
          <GreenText> trazer o futuro para o presente.</GreenText>
        </StyledText>
      </TextWrapper>
      <CustomVisibilitySensor partialVisibility minTopValue={500}>
        {({ isVisible }) => (
          <SideImageWrapper className={classNames({ isVisible })}>
            <SideImage alt="" src={require('assets/experiencia-banner.svg')} />
          </SideImageWrapper>
        )}
      </CustomVisibilitySensor>
    </Wrapper>
  );
}
