import styled from 'styled-components';
import { Parallax } from 'react-parallax';
import { LoadImage, Subtitle, Title } from 'components';
import { px2rem } from 'src/util';

const mobileHeight = 592;

export const Wrapper = styled.section`
  height: 0;
  position: relative;

  @media screen and (min-width: 998px) {
    padding-bottom: ${(921 / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    padding-bottom: ${(mobileHeight / 375) * 100}%;
  }
`;

export const ParallaxWrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const StyledParallax = styled(Parallax)`
  height: 100%;
  width: 100%;

  @media screen and (min-width: 998px) {
    img {
      margin-top: ${px2rem(220)};
    }
  }
`;

export const BackgroundImage = styled(LoadImage)`
  width: 100%;

  @media screen and (min-width: 998px) {
    left: 0;
    position: absolute;
    top: 0;
  }

  @media screen and (max-width: 997px) {
  }
`;

export const Overlay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  ${Subtitle} {
    color: #fff;
    margin-bottom: ${px2rem(40)};
    max-width: ${px2rem(290)};
  }

  ${Title} {
    color: #00e3d4;
    margin-bottom: ${(130 / 1920) * 100}%;
  }

  @media screen and (min-width: 998px) {
    padding-top: ${(192 / 1920) * 100}%;

    ${Title} {
      max-width: ${px2rem(500)};
    }
  }

  @media screen and (max-width: 997px) {
    padding: ${(246 / 375) * 100}% 20px 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  z-index: 1;

  @media screen and (min-width: 998px) {
    width: ${(1193 / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    width: 100%;
  }
`;

export const ScreensImage = styled(LoadImage)`
  filter: drop-shadow(
    ${px2rem(20)} ${px2rem(14)} ${px2rem(64)} rgba(54, 64, 152, 0.13)
  );
  width: 100%;
`;
