import React from 'react';
import { Title } from 'components';
import { Wrapper, StyledTitle, Logo } from './styles';
import { StyledText } from '../styles';

export default function Values() {
  return (
    <Wrapper>
      <StyledTitle>
        Driving <br /> healthcare <br /> forward.
      </StyledTitle>
      <Logo src={require('assets/logo.svg')} alt="Nexodata" />
    </Wrapper>
  );
}
