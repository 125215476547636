import styled from 'styled-components';
import { Text, Title } from 'components';

export const SmallTitle = styled(Title)`
  @media screen and (min-width: 998px) {
    font-size: 40px;
  }

  @media screen and (max-width: 997px) {
    font-size: 32px;
  }
`;

export const StyledText = styled(Text)`
  line-height: 133%;
`;

export const GreenText = styled.strong`
  color: #21aa91;
  font-weight: 700;
`;
