import React from 'react';
import { Subtitle, Title } from 'components';
import {
  Wrapper,
  ParallaxWrapper,
  StyledParallax,
  BackgroundImage,
  Overlay
} from './styles';
import { SmallTitle, StyledText, GreenText } from '../styles';

export default function Purpose() {
  return (
    <Wrapper>
      <BackgroundImage
        alt=""
        className="mobile"
        src={require('assets/proposito-mobile.jpg')}
      />
      <ParallaxWrapper>
        <StyledParallax
          bgImage={require('assets/proposito.jpg')}
          bgImageAlt=""
          className="desktop"
          strength={400}
        />
        <Overlay>
          <Subtitle>
            Esse é o nosso propósito porque sabemos o valor do tempo
          </Subtitle>
          <SmallTitle>
            Para nós, cada segundo faz diferença para quem precisa dos nossos
            serviços
          </SmallTitle>
          <StyledText>
            É com esse propósito que{' '}
            <GreenText>
              trabalhamos incansavelmente para ativar conexões e gerar
              inteligência
            </GreenText>{' '}
            para todas as partes do ecossistema de saúde.
          </StyledText>
        </Overlay>
      </ParallaxWrapper>
      {/* <BackgroundImage
        alt=""
        className="desktop"
        src={require('assets/proposito.jpg')}
      /> */}
    </Wrapper>
  );
}
