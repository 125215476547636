import React from 'react';
import { Subtitle, Title } from 'components';
import { Wrapper } from './styles';
import { SmallTitle, StyledText, GreenText } from '../styles';

export default function Integration() {
  return (
    <Wrapper>
      <Subtitle>Uma palavra resume o que é a Nexodata:</Subtitle>
      <Title>Integração</Title>
      <StyledText>
        Porque nós acreditamos que integração gera inteligência.
        <GreenText>E inteligência é a chave para a saúde do futuro</GreenText>
      </StyledText>
      <SmallTitle>
        A Nexodata nasceu para antecipar o futuro no sistema de saúde
      </SmallTitle>
      <StyledText>
        <GreenText>Esse é o nosso propósito maior</GreenText>
      </StyledText>
    </Wrapper>
  );
}
