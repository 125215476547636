import React from 'react';
import { Title } from 'components';
import {
  Wrapper,
  List,
  ListItem,
  ItemHeader,
  ItemIcon,
  ItemName
} from './styles';
import { StyledText } from '../styles';

export default function Values() {
  return (
    <Wrapper>
      <Title>Valores Nexodata</Title>
      <List>
        <ListItem>
          <ItemHeader>
            <ItemIcon src={require('assets/espirito.svg')} alt="" />
            <ItemName>Espírito de Hércules</ItemName>
          </ItemHeader>
          <StyledText>
            Grandes obras não nascem sem esforço extremo, inteligência e
            protagonismo.
          </StyledText>
        </ListItem>
        <ListItem>
          <ItemHeader>
            <ItemIcon src={require('assets/carater.svg')} alt="" />
            <ItemName>Caráter</ItemName>
          </ItemHeader>
          <StyledText>O que é certo, é certo.</StyledText>
        </ListItem>
        <ListItem>
          <ItemHeader>
            <ItemIcon src={require('assets/coragem.svg')} alt="" />
            <ItemName>Coragem para Mudar</ItemName>
          </ItemHeader>
          <StyledText>
            Ninguém antecipa o futuro sem inconformismo e capacidade de
            execução.
          </StyledText>
        </ListItem>
        <ListItem>
          <ItemHeader>
            <ItemIcon src={require('assets/tempo.svg')} alt="" />
            <ItemName>Valor do Tempo</ItemName>
          </ItemHeader>
          <StyledText>
            Cada segundo conta na missão de levar o melhor serviço sempre.
          </StyledText>
        </ListItem>
      </List>
    </Wrapper>
  );
}
